<template>
    <div>
        <div class="headerss">
            <span ></span>
            <span>车辆详情</span>
            <span></span>
        </div>
        <div class="detailMain">
            <div>
                <span>车牌号：</span>
                <span>{{labelList.carNumberPlate}}</span>
            </div>
            <div>
                <span>车架号：</span>
                <!-- <input type="text" name="" id="" v-model="labelList.vin" readonly="readonly">         -->
                <span>{{labelList.vin}}</span>
            </div>
            <div>
                <span>品牌：</span>
                <!-- <input type="text" name="" id="" v-model="labelList.jinchangbianhao" readonly="readonly">         -->
                <span>{{labelList.vehicleBrand}}</span>
            </div>
            <div>
                <span>型号：</span>
                <!-- <input type="text" name="" id="" v-model="labelList.chaibianhao" readonly="readonly">         -->
                <span>{{labelList.vehicleModel}}</span>
            </div>
            <div>
                <span>核定载客数：</span>
                <!-- <input type="text" name="" id="" v-model="labelList.CarAccessoriesText" readonly="readonly">         -->
                <span>{{labelList.approvedPassengersCapacity}}</span>
            </div>
            <div>
                <span>动力类别：</span>
                <!-- <input type="text" name="" id=""  v-model="labelList.patrsName" readonly="readonly">         -->
                <span>{{labelList.fuelTypeId_text}}</span>
            </div>
            <div>
                <span>使用性质：</span>
                <!-- <input type="text" name="" id=""  v-model="labelList.vehicleBrand" readonly="readonly">         -->
                <span>{{labelList.useAttributeId_text}}</span>
            </div>
            <div>
                <span>发动机号：</span>
                <!-- <input type="text" name="" id=""  v-model="labelList.vehicleModel" readonly="readonly">         -->
                <span>{{labelList.engineNumber}}</span>
            </div>
            <div>
                <span>发动机型号：</span>
                <!-- <input type="text" name="" id=""  v-model="labelList.dismantleEndTime" readonly="readonly">         -->
                <span>{{labelList.engineModel}}</span>
            </div>
            <div>
                <span>vin解析品牌：</span>
               
                <span>{{labelList.vinVehicleBrand}}</span>
            </div>
            <div>
                <span>vin解析型号：</span>
               
                <span>{{labelList.vinVehicleModel}}</span>
            </div>
            <div>
                <span>vin解析年款：</span>
                <span>{{labelList.vinYear}}</span>
            </div>
            <div>
                <span>过磅净重：</span>
                <span>{{labelList.netWeight}}KG</span>
            </div>
            <div>
                <span>车辆增费项目：</span>
                <span v-if="tableData.length==0">无数据</span>
            </div>
            <div class="table" v-if="tableData.length!=0">
                    <table >
                        <tr>
                            <td>项目</td>
                            <!-- <td>单价</td> -->
                            <td>数量</td>
                           <!--  <td>增费</td> -->
                        </tr>
                        <tr v-for="(i,index) in tableData" :key="i.id">
                            <td>{{i.name}}</td>
                            <!-- <td>{{i.price}}</td> -->
                            <td>{{i.num}}</td>
                            <!-- <td>{{i.num * i.price}}</td> -->
                           
                        </tr>
                    </table>
            </div>
            <div>
                <span>车辆扣费项目：</span>
                <span v-if="DetetData.length==0">无数据</span>
            </div>
            <div class="table"  v-if="DetetData.length!=0">
                <table >
                    <tr>
                        <td>项目</td>
                        <!-- <td>单价</td> -->
                        <td>数量</td>
                        <!-- <td>扣费</td> -->
                    </tr>
                    <tr v-for="(i,index) in DetetData" :key="i.id">
                        <td>{{i.name}}</td>
                        <!-- <td>{{i.price}}</td> -->
                        <td>{{i.num}}</td>
                        <!-- <td>{{i.num * i.price}}</td> -->
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    
    data(){
        return{
            labelList:'',
            tableData:[],
            DetetData:[]
        }
    },
    created(){
        //console.log(this.$route.query.id)
        this.$http.post("index.php/index/P_Parts_Sales_Library/getVehicles",{id:this.$route.query.id}).then(res=>{
            //console.log(res.data.data)
            this.labelList = res.data.data.info
            this.tableData = res.data.data.quality_price_detail
            this.DetetData = res.data.data.quality_price_deduction_detail
            //console.log(this.labelList)
        })
    }
}
</script>
<style lang="scss" scoped> 
.table{
    padding:10px;
    margin-top: 10px;
    table,table tr th, table tr td { border:1px solid #000; }
    table { 
        width: 100%;
        text-align: center; 
        border-collapse: collapse; 
        
    }
}
    .headerss {
  padding: 0 20px;
  background-color: cadetblue;
  height: 1rem;
  line-height: 1rem;
  color: white;
  font-size: 0.4rem;
  display: flex;

  justify-content: space-between;
  span {
    display: inline-block;
  }
  span:nth-child(1) {
    width: 30px;
    height: 30px;
    background-size: 100%;
    margin-top: 8px;
  }
}
  .detailMain {
        width: 90%;
        margin: 10px auto;
         font-size: 0.35rem;
          div {
            margin-top: 10px;
            span {
                width: 3.5rem;
                display: inline-block;
                text-align: center;
                margin-right: 23px;
            }
            input {
                width: 4rem;
                height: 0.5rem;
            }
            }
    }
</style>